import React, { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function FeatureCards() {
  const [isOpen, setIsOpen] = useState(false);

  const { t, i18n } = useTranslation();
  return (
    <div className="w-full py-[6rem] px-4 ">
      <Fade top duration={2000} triggerOnce>
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8"
        id="features"
      >
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center">
        <div className="flex-grow bg-blue-500">
          
         <h1 className="text-5xl sm:text-4xl md:text-7xl text-slate-100 font-bold mx-8 my-3">
            <span>$</span>499
          </h1>
          <h1 className="font-bold text-2xl sm:text-2xl md:text-4xl mx-8 text-slate-100 p-3">
            {t('investplan.head')}
          </h1>
          </div> {/* Top half with blue background */}
        
       <div className="flex-grow bg-gray-900">
        
       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_2')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_3')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

          
        </div>   {/* Bottom half with black background */}


          <button className="my-6 bg-gray-800 text-white font-bold hover:bg-blue-500 hover:text-slate-100 rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">{t('investplan.link')}</Link>
          </button>
        </div>


        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center">
        <div className="flex-grow bg-blue-500">
          
         <h1 className="text-5xl sm:text-4xl md:text-7xl text-slate-100 font-bold mx-8 my-3">
            <span>$</span>999
          </h1>
          <h1 className="font-bold text-3xl sm:text-4xl md:text->xl mx-8 text-slate-100 p-3">
            {t('investplan.head_2')}
          </h1>
          </div> {/* Top half with blue background */}
        
       <div className="flex-grow bg-gray-900">
        
       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_4')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_5')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_6')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

          
        </div>   {/* Bottom half with black background */}


          <button className="my-6 bg-gray-800 text-white font-bold hover:bg-blue-500 hover:text-slate-100 rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">{t('investplan.link_2')}</Link>
          </button>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center">
        <div className="flex-grow bg-blue-500">
          
         <h1 className="text-5xl sm:text-4xl md:text-7xl text-slate-100 font-bold mx-8 my-3">
            <span>$</span>4,999
          </h1>
          <h1 className="font-bold text-3xl sm:text-4xl md:text->xl mx-8 text-slate-100 p-3">
            {t('investplan.head_3')}
          </h1>
          </div> {/* Top half with blue background */}
        
       <div className="flex-grow bg-gray-900">
        
       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_7')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_8')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_9')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

          
        </div>   {/* Bottom half with black background */}


          <button className="my-6 bg-gray-800 text-white font-bold hover:bg-blue-500 hover:text-slate-100 rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">{t('investplan.link_3')}</Link>
          </button>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center">
        <div className="flex-grow bg-blue-500">
          
         <h1 className="text-5xl sm:text-4xl md:text-7xl text-slate-100 font-bold mx-8 my-3">
            <span>$</span>19,999
          </h1>
          <h1 className="font-bold text-3xl sm:text-4xl md:text->xl mx-8 text-slate-100 p-3">
            {t('investplan.head_4')}
          </h1>
          </div> {/* Top half with blue background */}
        
       <div className="flex-grow bg-gray-900">
        
       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_10')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_11')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_12')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

          
        </div>   {/* Bottom half with black background */}


          <button className="my-6 bg-gray-800 text-white font-bold hover:bg-blue-500 hover:text-slate-100 rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">{t('investplan.link_4')}</Link>
          </button>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center">
        <div className="flex-grow bg-blue-500">
          
         <h1 className="text-5xl sm:text-4xl md:text-7xl text-slate-100 font-bold mx-8 my-3">
            <span>$</span>49,999
          </h1>
          <h1 className="font-bold text-3xl sm:text-4xl md:text->xl mx-8 text-slate-100 p-3">
            {t('investplan.head_5')}
          </h1>
          </div> {/* Top half with blue background */}
        
       <div className="flex-grow bg-gray-900">
        
       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_13')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_14')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_15')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

          
        </div>   {/* Bottom half with black background */}


          <button className="my-6 bg-gray-800 text-white font-bold hover:bg-blue-500 hover:text-slate-100 rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">{t('investplan.link_5')}</Link>
          </button>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center">
        <div className="flex-grow bg-blue-500">
          
         <h1 className="text-5xl sm:text-4xl md:text-7xl text-slate-100 font-bold mx-8 my-3">
            <span>$</span>99,999
          </h1>
          <h1 className="font-bold text-3xl sm:text-4xl md:text->xl mx-8 text-slate-100 p-3">
            {t('investplan.head_6')}
          </h1>
          </div> {/* Top half with blue background */}
        
       <div className="flex-grow bg-gray-900">
        
       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_16')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_17')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

       <p className="text-white mx-8 my-7 relative">
  <span className="inline-block mb-2">{t('investplan.tile_18')}</span>
  <span className="absolute left-0 bottom-0 w-full h-[1px] bg-white"></span>
       </p>

          
        </div>   {/* Bottom half with black background */}


          <button className="my-6 bg-gray-800 text-white font-bold hover:bg-blue-500 hover:text-slate-100 rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">{t('investplan.link_6')}</Link>
          </button>
        </div>
      </div>
      </Fade>
    </div>
  );
}

export default FeatureCards;
