import React, { useState } from "react";
import { FaEnvelope, FaRegBell, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../Api/useAuth";
import { useDisclosure } from "@chakra-ui/react";
import UploadImageModel from "./UploadImageModel";

function Dashboardview({ user }) {
  const [open, setOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showDropDown = () => {
    setOpen(!open);
  };
  const { logOut } = useAuth();
  return (
    <div className="flex items-center justify-between h-[70px] shadow-lg px-[25px]">
      <div className="flex items-center rounded-[5px]">
        <input
          type="text"
          className="bg-[#F8F9FC] h-[40px] outline-none pl-[13px] w-[150px] md:w-[300px] rounded-[5px] placeholder:text-[14px] leading-[20px] font-normal"
          placeholder="Search for...."
        />
        <div className="bg-[#4E73DF] h-[40px] px-[14px] flex items-center justify-center cursor-pointer rounded-tr-[5px] rounded-br-[5px]">
          <FaSearch color="white" />
        </div>
      </div>
      <div
        className="flex items-center gap-[15px] relative"
        onClick={showDropDown}
      >
        <div className="flex items-center gap-[25px] border-r-[5px] pr-[25px]">
          <FaRegBell />
          <FaEnvelope />
        </div>
        <div className="flex items-center gap-[15px] relative">
          <img
            alt="img"
            src={
              user?.profilePicture
                ? user?.profilePicture
                : "https://img.icons8.com/fluency/48/test-account.png"
            }
            className="h-[40px] w-[40px] object-cover rounded-[100%]"
          />
          {/* <div className='h-[50px] w-[50px] rounded-full bg-[#4E73DF] cursor-pointer flex items-center justify-center relative'>
                <img src={Member10} alt='/img' className='w-[45px] rounded-full'/>
            </div> */}

          {open && (
            <div className="bg-white border h-[100px] w-[150px] absolute bottom-[-135px] z-20 right-0 pt-[15px] pl-[15px] space-y-[10px]">
              <p className="cursor-pointer hover:text-[blue] font-semibold">
                <Link onClick={onOpen}>Profile</Link>
              </p>
              <p
                onClick={logOut}
                className="cursor-pointer hover:text-[blue] font-semibold"
              >
                Log out
              </p>
            </div>
          )}
          <UploadImageModel user={user} isOpen={isOpen} onClose={onClose} />
        </div>
      </div>
    </div>
  );
}

export default Dashboardview;
