import React from "react";
import { GiPuzzle } from "react-icons/gi";
import { TbTargetArrow } from "react-icons/tb";
import { GiChart } from "react-icons/gi";
import { MdSupportAgent } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import '../i18n'

function WhyChooseUs() {
    const { t, i18n } = useTranslation();
    return(
        <div className="w-full px-4 py-[4rem]"> 
                    <h1 className="font-bold md:text-5xl sm:text-3xl text-gray-800 text-2xl text-center">{t('choose.head')}</h1>
            <div className="max-w-[1240px] mx-auto grid md:grid-cols-4 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center justify-center bg-slate-100 transform transition-transform duration-700 hover:scale-90 mt-10">
            <div className="flex items-center justify-center text-gray-700 p-10">
                <GiPuzzle size={40} />
            </div>
            <h1 className="font-bold text-center">{t('choose.body_1')}</h1>
            </div>

                <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center justify-center bg-slate-100 transform transition-transform duration-700 hover:scale-90 mt-10">
            <div className="flex items-center justify-center text-gray-700 p-10">
            <TbTargetArrow size={40} />
            </div>
            <h1 className="font-bold text-center">{t('choose.body_2')}</h1>
            </div>

                <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center justify-center bg-slate-100 transform transition-transform duration-700 hover:scale-90 mt-10">
             <div className="flex items-center justify-center text-gray-700 p-10">
             <GiChart size={40}/> 
            </div>
            <h1 className="font-bold text-center">{t('choose.body_3')}</h1>
            </div>

                <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-center justify-center bg-slate-100 transform transition-transform duration-700 hover:scale-90 mt-10">
            <div className="flex items-center justify-center text-gray-700 p-10">
            <MdSupportAgent size={40}/>
            </div>
             <h1 className="font-bold text-center">{t('choose.body_4')}</h1>
            </div>
            </div>
        </div>
    )
}

export default WhyChooseUs