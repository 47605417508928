import React from "react";
import YouTubeVid from "./YouTubeVid";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../i18n'

function WhatIsBitcoin() {
    const { t, i18n } = useTranslation();
    return(
        <div className="w-full mt-[-200px] sm:mt-[50px] md:mt-[100px] md:py-16 px-4">
            <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 px-4">
                <div className="flex flex-col justify-center">
                    <h1 className="text-gray-800 font bod md:text-6xl sm:text-4xl text-3xl font-bold">
                    {t('about.head')}
                    </h1>
                    <p className="p-3 text-[rgb(111,111,111)] font-bold">
                    {t('about.body')}
                    </p>

                    <button class='absolute text-white bg-gray-900 mt-8 w-[200px] font-medium py-3 cursor-pointer relative rounded-lg'><Link to='/register'>{t('about.button')}</Link></button>
                </div>
                <div className='md:mx-[150px] mt-[100px]'>
                    <YouTubeVid/>
                </div>
            </div>
        </div>
    )
}

export default WhatIsBitcoin