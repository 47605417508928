import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Contract() {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full py-[4rem] px-4 bg-gray-200">
      <Fade top duration={3000} triggerOnce>
      <div className="max-w-[1240px] mx-auto">
        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          {t('contract.head')}
        </h1>
        <p className="font-bold my-4 p-4 ">
        {t('contract.body')}
        </p>

        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          {t('contract.head_2')}
        </h1>
        <p className="font-bold my-4 p-4">
        {t('contract.body_2')}
        </p>

        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          {t('contract.head_3')}
        </h1>
        <p className="font-bold my-4 p-4">
        {t('contract.body_3')}
        </p>
      </div>
      </Fade>
    </div>
  );
}

export default Contract;
