// import Man1 from '../Assets/Man-1.jpg'
// import Man5 from '../Assets/Man-5.jpg'
// import Man3 from '../Assets/Man-3.jpg'
import Man6 from "../Assets/Man-6.avif";
// import Woman3 from '../Assets/Woman-3.jpg'
// import Woman8 from '../Assets/Woman-8.jpeg'
// import Woman6 from '../Assets/woman-6.jpg'
// import Woman7 from '../Assets/Woman-7.jpg'
// import Woman4 from '../Assets/Woman-4.jpg'
import Woman5 from "../Assets/Woman-5.jpg";

import Member1 from "../Assets/Member-1.jpg";
import Member2 from "../Assets/Member-2.jpg";
import Member3 from "../Assets/Member-3.jpg";
import Member4 from "../Assets/Member-4.jpg";
import Member5 from "../Assets/Member-5.jpg";
import Member6 from "../Assets/Member-6.jpg";
import Member7 from "../Assets/Member-7.jpg";
import Member8 from "../Assets/Member-8.jpg";
import Member9 from "../Assets/Woman-1.jpg";
import Member10 from "../Assets/Man-1.jpg";

const communityData = [
  {
    id: 1,
    image: Member1,
    name: "Tyler Stone",
  },
  {
    id: 2,
    image: Member2,
    name: "John Rickle",
  },
  {
    id: 3,
    image: Member3,
    name: "Evelyn Snow",
  },
  {
    id: 4,
    image: Member4,
    name: "Peter Crest",
  },
  {
    id: 5,
    image: Member5,
    name: "Jane Fishmore",
  },
  {
    id: 6,
    image: Member6,
    name: "Victor Stan",
  },
  {
    id: 7,
    image: Member7,
    name: "Jessie Sandler",
  },
  {
    id: 8,
    image: Member8,
    name: "Tracy Mill",
  },
  {
    id: 9,
    image: Member9,
    name: "Stacy Jones",
  },
  {
    id: 10,
    image: Member10,
    name: "Derek Passo",
  },
];

export default communityData;
