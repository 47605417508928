import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {
  LuChevronRight,
  LuChevronLeft,
  LuLayoutDashboard,
  LuLayoutList,
  LuPlusSquare,
  LuCrown,
  LuLogOut,
} from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Menue from "./menue";

function Sidebar({ open, isOpen }) {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="h-[100vh] flex flex-col items-center p-5">
      <Menue
        Icon={LuLayoutDashboard}
        isOpen={isOpen}
        text="Dashboard"
        top={0}
        bottom={10}
      />
      <Menue
        Icon={LuLayoutList}
        isOpen={isOpen}
        text="Layout"
        top={0}
        bottom={4}
      />
      <Menue
        Icon={LuPlusSquare}
        isOpen={isOpen}
        text="addon"
        top={0}
        bottom={4}
      />
      <Menue Icon={LuCrown} isOpen={isOpen} text="upgrade" top={0} bottom={4} />
      <Menue
        Icon={LuLogOut}
        isOpen={isOpen}
        text="logout"
        top={0}
        bottom={10}
      />

      {!isOpen && (
        <LuChevronRight
          onClick={() => {
            open(true);
          }}
          className="w-[40px] h-[40px] stroke-1 relative  transition ease-in duration-700"
        />
      )}
      {isOpen && (
        <div
          onClick={() => {
            open(false);
          }}
          className="flex items-center cursor-pointer gap-2 mb-4 h-[50px]"
        >
          <LuChevronLeft className="w-[27px] h-[27px] stroke-1 relative  transition ease-in duration-700" />
          <p className="w-[100px] tracking-wide capitalize">minimize</p>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
