import React from "react";
import { Link } from "react-router-dom";
import Commodity from "../Assets/Commodities.jpg";
import CryptoCurrency from "../Assets/Crypto.jpg";
import Mining from "../Assets/Mining.jpg";
import Indicies from "../Assets/Indices.jpg";
import Metals from "../Assets/Metals.jpg";
import Stocks from "../Assets/Stocks.jpg";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function MarketCards() {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full py-[6rem] px-4 ">
      <Fade top duration={2000} triggerOnce>
      <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-slate-100">
          <img className="rounded-lg bg-white" src={Commodity} alt="img" />
          <h1 className="font-bold text-gray-800 text-2xl">{t('marketcard.head')}</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            {t('marketcard.body')}
          </p>
          <h2 className="font-bold text-gray-800 underline p-3">
            <Link to="/commodities">{t('marketcard.link')}</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-slate-100">
          <img className="rounded-lg bg-white" src={CryptoCurrency} alt="img" />
          <h1 className="font-bold text-gray-800 text-2xl">{t('marketcard.head_2')}</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
           {t('marketcard.body_2')}
          </p>
          <h2 className="font-bold text-gray-800 underline p-3">
            <Link to="/cryptocurrencies">{t('marketcard.link_2')}</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-slate-100">
          <img className="rounded-lg bg-white" src={Mining} alt="img" />
          <h1 className="font-bold text-gray-800 text-2xl">{t('marketcard.head_3')}</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            {t('marketcard.body_3')}
          </p>
          <h2 className="font-bold text-gray-800 underline p-3">
            <Link to="/cryptomining">{t('marketcard.link_3')}</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-slate-100">
          <img className="rounded-lg bg-white" src={Indicies} alt="img" />
          <h1 className="font-bold text-gray-800 text-2xl">{t('marketcard.head_4')}</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            {t('marketcard.body_4')}
          </p>
          <h2 className="font-bold text-gray-800 underline p-3">
            <Link to="/indices">{t('marketcard.link_4')}</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-slate-100">
          <img className="rounded-lg bg-white" src={Metals} alt="img" />
          <h1 className="font-bold text-gray-800 text-2xl">{t('marketcard.head_5')}</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            {t('marketcard.body_5')}
          </p>
          <h2 className="font-bold text-gray-800 underline p-3">
            <Link to="/metals">{t('marketcard.link_5')}</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-slate-100">
          <img className="rounded-lg bg-white" src={Stocks} alt="img" />
          <h1 className="font-bold text-gray-800 text-2xl">{t('marketcard.head_6')}</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            {t('marketcard.body_6')}
          </p>
          <h2 className="font-bold text-gray-800 underline p-3">
            <Link to="/stocks">{t('marketcard.link_6')}</Link>
          </h2>
        </div>
      </div>
      </Fade>
    </div>
  );
}

export default MarketCards;
