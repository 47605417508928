import axios from "axios";
import { parseCookies, destroyCookie } from "nookies";

export const useAuth = () => {
  const apiRoute = "https://star-vgx1.onrender.com";
  const cookies = parseCookies();
  const { userId } = cookies;
  const config = {
    headers: {
      Authorization: `Bearer ${userId}`,
    },
  };

  const DeleteUser = async (id) => {
    try {
      const res = await axios.post(`${apiRoute}/auth/delete-user/${id}`);
      console.log(res, "delet");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const signUp = async (data, refer) => {
    try {
      const res = await axios.post(`${apiRoute}/auth/register`, data);
      if (refer) {
        if (res.data.status) {
          const referRes = await axios.post(`${apiRoute}/auth/refer/${refer}`, {
            userId: res.data.userId,
          });
          if (referRes.data.status) {
            return res;
          } else {
            try {
              const delres = await DeleteUser(res.data.userId);
              console.log(delres, "delres");
            } catch (err) {
              throw err;
            }
            throw new Error(referRes.data.message);
          }
        } else {
          throw new Error(res.data.message);
        }
      } else {
        return res;
      }
    } catch (err) {
      throw err;
    }
  };

  const signIn = async (data) => {
    try {
      const res = axios.post(`${apiRoute}/auth/login`, data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const UploadProfileImage = async (formdata) => {
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dazk39xwg/image/upload",
        formdata
      );
      const data = await response.data;
      try {
        const res = await axios.patch(
          `${apiRoute}/app/upload-avatar`,
          { avatarUrl: data.secure_url },
          config
        );
        return res;
      } catch (err) {
        throw err;
      }
    } catch (err) {
      throw err;
    }
  };
  const GetUserInfo = async () => {
    try {
      const res = await axios.get(`${apiRoute}/app/get-profile`, config);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const AddMoney = async (data) => {
    try {
      const res = await axios.post(`${apiRoute}/app/addAmount`,{amount:data}, config);
      return res;
    } catch (err) {
      throw err;
    }
  };
  const logOut = () => {
    destroyCookie(null, "userId");
    window.location.href = "/login";
  };

  return { signUp, signIn, logOut, UploadProfileImage, GetUserInfo, AddMoney };
};
