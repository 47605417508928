import React, { useState, useCallback, useEffect } from "react";

const ResizableComponent = React.memo(({ children }) => {
  const [ref, setRef] = useState(null);

  const handleResize = useCallback((entries) => {
    // Handle resize here
  }, []);

  useEffect(() => {
    if (!ref) return;

    const observer = new ResizeObserver(handleResize);
    observer.observe(ref);

    return () => observer.disconnect();
  }, [ref, handleResize]);

  return <div ref={setRef}>{children}</div>;
});

export default ResizableComponent;
