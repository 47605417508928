import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import Star from "../Assets/Starfire.png";
import { useTranslation } from 'react-i18next';
import '../i18n'

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

 const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);

  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const toggleDropdown3 = () => {
    setShowDropdown3(!showDropdown3);
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4  text-blue-400">
      <img src={Star} alt="/img" className="w-[150px] h-[150px]" />
      <h1 className="w-full text-4xl font-bold text-gray-800 mx-8">
        <Link to="/">STARFIRE</Link>
      </h1>
      <ul className="lg:flex cursor-pointer w-full hidden">

      <li className="relative group">
        {/* Navbar dropdown */}
          
        <div
          className="flex items-center p-4 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 rounded-full text-gray-800"
          onClick={toggleDropdown1}
        >
          <span><Link to='/about'>{t('nav.about')}</Link></span>
          {showDropdown1 ? <HiChevronUp /> : <HiChevronDown />}
        </div>
        <ul
          className={`absolute left-0 mt-2 space-y-2 bg-white text-black ${
            showDropdown1 ? "block" : "hidden"
          } z-10 transition-all duration-700`}
        >
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/license">{t('nav.license')}</Link>
              </p>
          </li>
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/foundation">{t('founders.head')}</Link>
              </p>
          </li>
          {/* Add more dropdown links as needed */}
        </ul>
      </li>
      <li className="relative group">
        <div
          className="flex items-center p-4 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 rounded-full text-gray-800 whitespace-nowrap"
          onClick={toggleDropdown2}
        >
          <span><Link to='/tradingconditions'>{t('nav.trade')}</Link></span>
          {showDropdown2 ? <HiChevronUp /> : <HiChevronDown />}
        </div>
        <ul
          className={`absolute left-0 mt-2 space-y-2 bg-white text-black ${
            showDropdown2 ? "block" : "hidden"
          } z-10`}
        >
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/faq">{t('nav.faq')}</Link>
            </p>
          </li>
        
        </ul>
      </li>

      <li className="relative group">
        <div
          className="flex items-center p-4 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 rounded-full text-gray-800"
          onClick={toggleDropdown3}
        >
          <span><Link to='/market'>{t('nav.market')}</Link></span>
          {showDropdown3 ? <HiChevronUp /> : <HiChevronDown />}
        </div>
        <ul
          className={`absolute left-0 mt-2 space-y-2 bg-white text-black ${
            showDropdown3 ? "block" : "hidden"
          } z-10`}
        >
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/cryptocurrencies">{t('nav.currencies')}</Link>
            </p>
          </li>
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/commodities">{t('nav.commodities')}</Link>
            </p>
          </li>
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/cryptomining">{t('nav.mining')}</Link>
            </p>
          </li>
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/metals">{t('nav.metal')}</Link>
            </p>
          </li>
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/indices">{t('nav.indices')}</Link>
            </p>
          </li>
          <li>
            <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
              <Link to="/stocks">{t('nav.stock')}</Link>
            </p>
          </li>
         
        </ul>
      </li>

      <li className="p-4 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 rounded-full text-gray-800">
        <Link to="/register">{t('nav.register')}</Link>
      </li>
      <li className="p-4 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 rounded-full text-gray-800">
        <Link to="/login">{t('nav.login')}</Link>
      </li>
    </ul>




      <div onClick={handleNav} className="block lg:hidden z-20 cursor-pointer">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-slate-100 ease-in-out duration-500 z-10"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-gray-800 m-4">
          STARFIRE
        </h1>
        <li className="relative group">
          {/* Navbar dropdown */}
          <p className="p-4 border-b text-gray-800 border-gray-600 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 ">
            <Link to="/about">{t('nav.about')}</Link>
          </p>
          <ul className="left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/license">{t('nav.license')}</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/foundation">{t('founders.head')}</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>

        <li className="relative group">
          <p className="p-4 text-gray-800 border-b border-gray-600 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 ">
            <Link to="/tradingconditions">{t('nav.trade')}</Link>
          </p>
          <ul className=" left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px] z-1">
                <Link to="/faq">{t('nav.faq')}</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>

        <li className="relative group ">
          <p className="p-4 border-b text-gray-800 border-gray-600 hover:bg-gray-800 font-bold hover:text-white transition-colors duration-700 ">
            <Link to="/market">{t('nav.market')}</Link>
          </p>
          <ul className=" left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/cryptocurrencies">{t('nav.currencies')}</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/commodities">{t('nav.commodities')}</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/cryptomining">{t('nav.mining')}</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/metals">{t('nav.metal')}</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/indices">{t('nav.indices')}</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-gray-800 hover:text-white hover:rounded-[10px]">
                <Link to="/stocks">{t('nav.stock')}</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>
        <li className="p-4 text-gray-800 border-b border-gray-600 font-bold">
          <Link to="/register">{t('nav.register')}</Link>
        </li>
        <li className="p-4 text-gray-800 font-bold">
          <Link to="/login">{t('nav.login')}</Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
