import React from "react";
import Megaphone from "../Assets/Megaphone.png";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Features() {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="w-full py-16 lg:h-[300px] text-white px-4 bg-gray-800"
      id="referral"
    >
      <Fade top duration={2000} tri>
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div>
          <h1 className="text-2xl sm:text-3xl md:text-4xl">
          {t('refer.head')}
          </h1>
          <p className="my-6 font-bold underline">
            <Link to="/register">{t('refer.link')}</Link>
          </p>
        </div>
        <img
          className="mx-auto mt-[-50px] my-4 w-[200px]"
          src={Megaphone}
          alt="img"
        />
      </div>
      </Fade>
    </div>
  );
}

export default Features;
