import React from "react";
import {
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-full bg-gray-800 mx-auto grid lg:grid-cols-3 gap-8 text-white py-[6rem] px-4">
      <div>
        <h1 className="w-full text-3xl font-bold">
          <Link to="/">STARFIRE INVEST</Link>
        </h1>
        <p className="py-4">
          {t('footer.text')}
        </p>
        <div className="flex gap-6 md:w-[75%] my-6">
          <FaFacebookSquare size={30} className="hover:text-slate-400 cursor-pointer"/>
          {/* <FaLinkedin size={30}/> */}
          <a href='https://www.instagram.com/starfire.invest?igsh=MW9uMmY1ZXJIY3drZQ=='><FaInstagram size={30} className="hover:text-slate-400 cursor-pointer"/></a>
          
          {/* <FaGithubSquare size={30}/>
            <FaTwitterSquare size={30}/> */}
        </div>
      </div>

      <div className="lg:col-span-2 flex justify-between mt-6">
        <div className="mr-2">
          <h1 className="font-medium md:text-3xl text-white hover:text-slate-400">
            <Link to="/about">{t('footer.abt')}</Link>
          </h1>
          <h1 className="font-medium md:text-3xl text-white hover:text-slate-400">
            <Link to="/contact">{t('footer.ctc')}</Link>
          </h1>
          <h1 className="font-medium md:text-3xl text-white hover:text-slate-400">
            <NavLink to="/#features">{t('footer.ft')}</NavLink>
          </h1>
        </div>

        <div>
          <h6 className="font-medium text-[rgb(111,111,111)]">
            <Link to="/tradingconditions">
            {t('footer.tra-c')}
            </Link>
          </h6>
          <ul>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/faq">FAQ</Link>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/license">{t('footer.li-a')}</Link>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/commodities">{t('footer.cmd')}</Link>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/cryptomining">{t('footer.cpm')}</Link>
            </li>
          </ul>
        </div>

        <div>
          <h6 className="font-medium text-[rgb(111,111,111)]">
            <Link to="/market">{t('footer.mkt')}</Link>
          </h6>
          <ul>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/cryptocurrencies">{t('footer.cc')}</Link>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/indices">{t('footer.ids')}</Link>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/metals">{t('footer.mts')}</Link>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <Link to="/stocks">{t('footer.sts')}</Link>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <NavLink to="/#benefits">{t('footer.bnf')}</NavLink>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <NavLink to="/#tradingsteps">{t('footer.tra-s')}</NavLink>
            </li>
            <li className="py-2 text-sm font-bold hover:text-slate-400">
              <NavLink to="/#referral">{t('footer.ref')}</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
