import React from "react";
import Benefit from "../Assets/Benefits.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Benefits() {

  const { t, i18n } = useTranslation();
  return (
    <div className="w-full py-16 px-4 bg-gray-800">
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8"
        id="benefits"
      >
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Benefit}
          alt="img"
        />
        <div className="flex flex-col justify-center">
          <h1 className="text-white font-bold md:text-6xl sm:text-4xl text-3xl md:mx-3">
            {t('benefits.head')}
          </h1>
          <p className="font-bold text-white p-2">
            {t('benefits.body')}
          </p>
          <h2 className="font-bold text-white underline p-3">
            <Link to="/market">{t('benefits.link')}</Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
