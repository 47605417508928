import React, { useState, useEffect, useTransition } from 'react';
import image1 from '../Assets/Busi 2.jpg';
import image2 from '../Assets/max.jpg';
import image3 from '../Assets/bit.jpg';
import image4 from '../Assets/chart.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../i18n' 
import './Hero.css'; // Import CSS file for custom styles

function Hero() {
 
  // Array of images
  const images = [image1, image2, image3, image4];

  // Initialize state for current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // const [transitionClass, setTransitionClass] = useState('');

  // Function to increment current image index
  const incrementImageIndex = () => {
     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Use useEffect to set up interval for image change
  useEffect(() => {
    const interval = setInterval(incrementImageIndex, 5000); // Change image every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Run effect only once on component mount

 const { t, i18n } = useTranslation();

  return (
    <div>
      <div className='w-full overflow-hidden h-[550px] md:h-[800px] relative'>
        {/* Display current image with slide animation */}
        <div className="flex" style={{ transform: `translateX(-${currentImageIndex * 100}%)`, transition: 'transform 0.5s ease-in-out' }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className="w-full h-full object-cover"
            alt={`img-${index}`}
          />
        ))}
      </div>

        <div className='md:p-32 px-4 py-10 mt-13 md:mt-16 absolute justify-center top-0 left-0 right-0 flex flex-col'>
        <h1 className='text-slate-300 text-4xl font-bold'>{t('main.header')}</h1>
          <h2 className='text-slate-300 text-3xl md:text-6xl font-bold mt-5'>{t('main.hero')}</h2>
          <button className='absolute text-white bg-gray-900 hover:bg-white hover:text-gray-700 mt-8 w-[200px] font-medium py-3 cursor-pointer relative rounded-lg'>
            <Link to='/register'>{t('main.hero_button')}</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Hero;




