import React from "react";
import { FaStar, FaStarHalf } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Reviews() {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-full px-4 py-[4rem]">
      <Fade top duration={2000} triggerOnce>
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-blue-100 transform transition-transform duration-700 hover:scale-90">
          <p className="font-bold my-3 text-[rgb(111,111,111)]">
            {t('review.text')}
          </p>
          <h1 className="text-center text-gray-800 my-3 text-2xl font-bold">
            Michael Bornfield
          </h1>
          <h4 className="text-center text-gray-800 my-3">{t('review.job')}</h4>
          <ul className="flex justify-center">
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
          </ul>
        </div>

        <div className="shadow-xl justify-center flex flex-col p-2 md:my-0 my-8 rounded-lg bg-blue-100 transform transition-transform duration-700 hover:scale-90">
          <p className="font-bold text-[rgb(111,111,111)]">
            {t('review.text')}
          </p>
          <h1 className="text-center text-gray-800 my-3 text-2xl font-bold">
            Robert Pattinson
          </h1>
          <h4 className="text-center text-gray-800 my-3">{t('review.job')}</h4>
          <ul className="flex justify-center">
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStarHalf className="text-yellow-500" />
            </li>
          </ul>
        </div>

        <div className="shadow-xl justify-center flex flex-col p-2 my-4 rounded-lg bg-blue-100 transform transition-transform duration-700 hover:scale-90">
          <p className="font-bold my-3 text-[rgb(111,111,111)]">
            {t('review.text')}
          </p>
          <h1 className="text-center text-gray-800 my-3 text-2xl font-bold">
            Catherine Buble
          </h1>
          <h4 className="text-center text-gray-800 my-3">{t('review.job')}</h4>
          <ul className="flex justify-center">
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
          </ul>
        </div>
      </div>
      </Fade>
    </div>
  );
}

export default Reviews;
