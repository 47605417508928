import React from 'react';
import YouTube from 'react-youtube';

const YouTubeVid = () => {
    // YouTube video ID
    const videoId = 'Gc2en3nHxA4';
  
    // Options for the YouTube player
    const opts = {
      height: '220',
      width: '350',
      playerVars: {
        autoplay: 0,
      },
    };
  
    return (
      <div>
        <YouTube videoId={videoId} opts={opts} />
      </div>
    );
  };
  
export default YouTubeVid;