import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Max from "../Assets/max.jpg";
import Portfolio2 from "../Assets/Portfolio-2.jpg";
import { FaChartPie, FaMicrochip, FaSearchDollar } from "react-icons/fa";
// import faqdata from "./FAQinput";
import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

function About() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Navbar />
      <Trial />
      <div className="w-full text-white pb-10 ">
        <div className="px-4 pb-10 mt-[66px] max-w-[1330px] mx-auto bg-gray-800 rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              {t('abtpage.head')}
            </h1>
          </div>
        </div>
        <div className="py-16 w-full px-4 h-[90vh] md:h-[35vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl">
              {t('abtpage.head-2')}
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
              {t('abtpage.text-1')}
              </p>
              <h4 className="underline font-bold text-gray-800 p-3">
                <Link to="/register" className="link">
                  {t('abtpage.link-1')}
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Max}
              alt="img"
            />
          </div>
        </div>
      </div>
      <div className="py-20 px-4 h-[80vh] md:h-[30vh] lg:h-[60vh]">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
          <img
            className="w-[500px] mx-auto my-4 rounded-[20px]"
            src={Portfolio2}
            alt="img"
          />
          <div className="flex flex-col justify-center">
            <h1 className="text-gray-800 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
              {t('abtpage.head-3')}
            </h1>

            <p className="p-3 text-[rgb(111,111,111)] font-bold">
            {t('abtpage.text-2')}
            </p>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
            {t('abtpage.text-3')}
            </p>
          </div>
        </div>
      </div>

      <div className="py-20 px-4">
        <div className="max-w-[1240px] mx-auto py-10 px-4 grid lg:grid-cols-3 gap-10">
          <div>
            <h1 className="w-full text-4xl font-bold text-gray-800">
              {t('abtpage.text-4')}
            </h1>
            <p className="py-4 font-bold text-[rgb(111,111,111)]">
              {t('abtpage.head-4')}
            </p>
          </div>
          <div className="lg:col-span-2 sm:flex sm:flex-row md:flex gap justify-between mt-6">
            <div>
              <FaMicrochip className="text-blue-400 text-center text-3xl mb-3" />
              <h1 className="font-bold text-2xl text-gray-800">
                {t('abtpage.head-5')}
              </h1>
              <p className="p-2 font-bold text-[rgb(111,111,111)]">
                {t('abtpage.text-5')}
              </p>
            </div>

            <div>
              <FaSearchDollar className="text-blue-400 text-3xl mb-3" />
              <h1 className="font-bold text-center text-2xl text-gray-800">
                {t('abtpage.head-6')}
              </h1>
              <p className="p-2 font-bold text-[rgb(111,111,111)]">
              {t('abtpage.text-6')}
              </p>
            </div>

            <div>
              <FaChartPie className="text-blue-400 text-3xl mb-3" />
              <h1 className="font-bold text-2xl text-gray-800">
                {t('abtpage.head-7')}
              </h1>
              <p className="p-2 font-bold text-[rgb(111,111,111)]">
              {t('abtpage.text-7')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-4">
        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-5xl text-5xl text-center pb-4">
          FAQ
        </h1>
        <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
         
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('abtpage.abt-faq-head-1')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('abtpage.abt-faq-text-1')}
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('abtpage.abt-faq-head-2')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('abtpage.abt-faq-text-2')}
              </p>
            </div>
            
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('abtpage.abt-faq-head-3')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('abtpage.abt-faq-text-3')}
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('abtpage.abt-faq-head-4')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('abtpage.abt-faq-text-4')}
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('abtpage.abt-faq-head-5')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('abtpage.abt-faq-text-5')}
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('abtpage.abt-faq-head-6')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('abtpage.abt-faq-text-6')}
              </p>
            </div>
      
        </div>
        <p className="text-center underline text-gray-800 font-bold pb-8">
          <Link to="/faq">{t('abtpage.link-2')}</Link>
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default About;
