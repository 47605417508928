import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import faqinfo from "./FAQInfo";
import faqinfo2 from "./FAQinfo2";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

function FAQ() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <Trial />
      <div className="w-full pb-10 text-white">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-gray-800 rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
            {t('faq-page.head')}
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center py-[4rem]">
        <h1 className="text-gray-800 mx-auto text-center font-bold md:text-6xl sm:text-4xl text-3xl">
          {t('faq-page.head-2')}
        </h1>

        <h3 className="text-gray-800 text-2xl mx-auto py-[4rem] font-bold">
          {t('faq-page.head-3')}
        </h3>
      </div>

      <div className="w-full px-4">
        <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
          
            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-4')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-1')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-5')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-2')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-6')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-3')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-7')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-4')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-8')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-5')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-9')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-6')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-10')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-7')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-11')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-8')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-12')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-9')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-13')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-10')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-14')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-11')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-15')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-12')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-16')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-13')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-17')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-14')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-18')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-15')}
              </p>
            </div>
         
        </div>
      </div>











      <div className="flex justify-center">
        <h3 className="mx-auto text-2xl font-bold text-gray-800 py-[4rem] underline">
        {t('faq-page.curi')}
        </h3>
      </div>

      <div className="w-full px-4 py-[4rem]">
        <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
          
            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-19')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-16')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-20')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-17')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-21')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-18')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-22')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-19')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-23')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-20')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-24')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-21')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-25')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-22')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-26')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-23')}
              </p>
            </div>

            <div
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('faq-page.head-27')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {t('faq-page.text-24')}
              </p>
            </div>
          
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FAQ;
