import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { useAuth } from "../Api/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UploadImageModel({ isOpen, onClose,user }) {
  const [formData, setFormData] = useState(null);
  const [img, setImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { UploadProfileImage } = useAuth();
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    const files = acceptedFiles;
    const formParam = new FormData();
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      setImg(file.name);
      formParam.append("file", file);
      formParam.append("upload_preset", "ml_default");
      setFormData(formParam);
    }
  }, []);
  const onCancle = () => {
    setImg("");
    setFormData(null);
    onClose();
  };
  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await UploadProfileImage(formData);
      if (res.data.status) {
        toast.success(res?.data?.message);
        setIsLoading(false);
        window.location.href = "/dashboard";
      } else {
        toast.error(res?.data?.message);
        setIsLoading(false);
      }
      console.log(res, "from the front end ");
    } catch (err) {
      console.log(err);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profile </ModalHeader>
        <ModalCloseButton />

        <ModalBody className="p-4">
        <h3 className='font-[600] mb-4'>Upload your profile photo </h3>
          <div className="border-dashed border-4 ">
            <div {...getRootProps()}>
              <input {...getInputProps()} accept="image/*" />

              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop your image here, or click to select files</p>
              )}
            </div>
          </div>
          <p>{img && img}</p>
          <ToastContainer />
         <div className="mt-2 flex">
        
       <Button colorScheme="blue" onClick={onSubmit} isDisabled={!formData}>
         {isLoading ? "loading" : "Upload"}
       </Button>
         </div>
                <h3 className='font-[600] my-4'>Referal code</h3>
                <p className="bg-blue-300 p-4 mt-4 rounded-sm ">{user?.referalCode}</p>
        </ModalBody>

        <ModalFooter>
        <Button
         colorScheme="red"
         isDisabled={isLoading}
         mr={3}
         onClick={onCancle}
       >
         Cancel
       </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UploadImageModel;
