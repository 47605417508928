import React from "react";
import { Link } from "react-router-dom";
import Investor from "../Assets/Invest.png";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Investors() {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-full bg-white py-16 px-4 md:h-[40vh] lg:h-[50vh]">
      <Fade top duration={2000} triggerOnce>
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl">
            {t('investors.head')}
          </h1>
          <p className="p-3 text-[rgb(111,111,111)] font-bold">
           
{t('investors.body_1')}
          </p>
          <p className="p-3 text-[rgb(87,62,62)] font-bold">
          {t('investors.body_2')}
          </p>
          <h2 className="underline font-bold text-gray-800 p-3">
            <Link to="/about">{t('investors.link')}</Link>
          </h2>
        </div>
        <img className="w-[500px] mx-auto my-4" src={Investor} alt="img" />
      </div>
      </Fade>
    </div>
  );
}

export default Investors;
