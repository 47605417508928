import React from "react";
import Compass from "../Assets/Compass.jpg";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function TradingSteps() {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full py-16 px-4">
      <Fade top duration={2000} triggerOnce>
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8"
        id="tradingsteps"
      >
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Compass}
          alt="img"
        />
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-gray-800 md:text-4xl sm:text-3xl text-2xl md:p-4">
            {t('join.head')}
          </h1>
          <div>
            <ol>
              <li>
                <p className="font-bold text-[rgb(111,111,111)] p-2">
                  
                  {t('join.list')}
                </p>
              </li>
              <li>
                <p className="font-bold text-[rgb(111,111,111)] p-2">
                  
                  {t('join.list_2')}
                </p>
              </li>
              <li>
                <p className="font-bold text-[rgb(111,111,111)] p-2">
                   {t('join.list_3')}
                </p>
              </li>
            </ol>
          </div>
          <h2 className="font-bold text-gray-800 p-3 underline">
            <Link to="/register">{t('join.link')}</Link>
          </h2>
        </div>
      </div>
      </Fade>
    </div>
  );
}

export default TradingSteps;
