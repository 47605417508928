import React from "react";
import { FaMoneyCheckAlt, FaChartLine, FaChartPie } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import '../i18n'

function InvestmentFactors() {
  const { t, i18n } = useTranslation();

  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-10 md:mt-[100px]">
      <div>
        <h1 className="w-full text-4xl font-bold text-gray-800">
          {t('factors.head')}
        </h1>
        <p className="py-4 font-bold text-[rgb(111,111,111)]">
          {t('factors.body')}
        </p>
      </div>
      <div
        className="lg:col-span-2 sm:flex sm:flex-row md:flex gap-8 justify-between mt-6"
        id="factors"
      >
        <div>
          <FaMoneyCheckAlt className="text-gray-800 text-3xl mb-3" />
          <h1 className="font-bold text-2xl text-gray-800 ">
            {t('factors.head_2')}
          </h1>
          <p className="p-2  text-[rgb(111,111,111)] font-bold">
            {t('factors.body_2')}
          </p>
        </div>
        <div>
          <FaChartLine className="text-gray-800 text-3xl mb-3" />
          <h1 className="font-bold text-2xl text-gray-800 ">
            {t('factors.head_3')}
          </h1>
          <p className="p-2  font-bold text-[rgb(111,111,111)]">
            {t('factors.body_3')}
          </p>
        </div>
        <div>
          <FaChartPie className="text-gray-800 text-3xl mb-3" />
          <h1 className="font-bold text-2xl text-gray-800 ">
            {t('factors.head_4')}
          </h1>
          <p className="p-2  font-bold text-[rgb(111,111,111)]">
            {t('factors.body_4')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InvestmentFactors;