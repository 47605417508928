import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
const data = [
  { name: "Trading", value: Math.floor(Math.random() * 50000) },
  { name: "Mining", value: Math.floor(Math.random() * 50000) },
  { name: "Stocks", value: Math.floor(Math.random() * 50000) },
  { name: "Metals", value: Math.floor(Math.random() * 50000) },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  // Rest of the code for rendering the label

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function PieComponent() {
  return (
    <div>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div className="grid grid-cols-4">
        {data.map((item, index) => (
          <p key={index} className="cursor-pointer font-bold mx-auto">
            {item.name}
          </p>
        ))}
        {COLORS.map((item, index) => (
          <div
            className="h-[30px] mx-auto w-[30px]"
            style={{ backgroundColor: item }}
            key={index}
          ></div>
        ))}
      </div>
      {/* <div className='grid grid-cols-2 md:grid-cols-4 mt-[15px] pb-[15px]'>
            {COLORS.map((item,index) =>
              <div className='h-[30px] mx-auto w-[30px]' style={{backgroundColor:item}} key={index}>
                
              </div>
            )}
        </div> */}
    </div>
  );
}

export default PieComponent;
