import React from "react";
import { Link } from "react-router-dom";
import Partner from "../Assets/Partnership.jpg";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Partnership() {
  const { t, i18n } = useTranslation();
  return (
    <div className=" w-full py-16 px-4">
      <Fade top duration={2000} triggerOnce>
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-6xl text-gray-800">
            {t('whyinvest.head')}
          </h1>
          <p className="font-bold text-[rgb(111,111,111)] p-3">
          {t('whyinvest.body')}
          </p>
          <h2 className="underline font-bold text-gray-800 p-3">
            <Link to="/register">{t('whyinvest.link')}</Link>
          </h2>
        </div>
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Partner}
          alt="img"
        />
      </div>
      </Fade>
    </div>
  );
}

export default Partnership;
