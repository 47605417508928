import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

// const ScrollToTop = (props) => {
//     const location = useLocation();
//     useEffect(() => {
//         if(!location.hash) {
//             window.scrollTo(0, 0)
//         }
//     }, [location]);

//     return <>{props.children}</>
// }

// export default ScrollToTop;

// const ScrollToTop = () =>{
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [])
// }

// return

// export default ScrollToTop

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default ScrollToTop;
