import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';

const Table = () => {
  const { t } = useTranslation();

  const generateRandomAmount = () => Math.floor(Math.random() * 9000) + 1000;

  const generateRandomFlag = () => {
    const flags = [
      'https://flagcdn.com/16x12/us.png',
      'https://flagcdn.com/16x12/gb.png',
      'https://flagcdn.com/16x12/ca.png',
      'https://flagcdn.com/16x12/au.png',
      'https://flagcdn.com/16x12/de.png',
      'https://flagcdn.com/16x12/fr.png',
      'https://flagcdn.com/16x12/it.png',
      'https://flagcdn.com/16x12/jp.png',
      'https://flagcdn.com/16x12/cn.png',
      'https://flagcdn.com/16x12/ru.png'
    ];
    return flags[Math.floor(Math.random() * flags.length)];
  };

  const generateRandomName = () => {
    const names = ['John', 'Alice', 'Bob', 'Emma', 'Michael', 'Sophia', 'David', 'Olivia', 'James', 'Emily', 'Trevor', 'Stephen', 'Bryan', 'Derek', 'Tega', 'Bradly', 'Kylie', 'Casey', 'Jane', 'Deborah', 'Quincy'];
    return names[Math.floor(Math.random() * names.length)];
  };

  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    const initialDeposits = Array.from({ length: 50 }, generateRandomAmount);
    const initialWithdrawals = Array.from({ length: 50 }, generateRandomAmount);
    setDeposits(initialDeposits);
    setWithdrawals(initialWithdrawals);

    const interval = setInterval(() => {
      setDeposits(Array.from({ length: 50 }, generateRandomAmount));
      setWithdrawals(Array.from({ length: 50 }, generateRandomAmount));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='w-full p-4'>
      <h1 className='text-gray-800 p-8 text-center font-bold text-2xl sm:text-3xl md:text-5xl'>{t('table.head')}</h1>
      <div className="flex justify-center flex-col sm:flex-row">
        <div className="overflow-x-auto mb-8 sm:mb-0">
          <table className="md:mx-4 border border-black divide-x divide-black w-full sm:w-[500px]">
            <thead className='bg-blue-700 text-white'>
              <tr>
                <th className="border border-black p-2">{t('table.text_1')}</th>
                <th className="border border-black p-2">{t('table.text_2')}</th>
                <th className="border border-black p-2">{t('table.text_3')}</th>
              </tr>
            </thead>
            <tbody>
              {deposits.map((amount, index) => (
                <tr key={index}>
                  <td className="border text-center border-black p-2"><span className='font-bold'>$</span> {amount}</td>
                  <td className="border text-center border-black p-2 overflow-hidden">{generateRandomName()}</td>
                  <td className="border text-center border-black p-2">
                    <img src={generateRandomFlag()} className='mx-auto' alt={`Flag ${index}`} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-10"></div>
        <div className="overflow-x-auto">
          <table className="md:mx-4 border border-black divide-x divide-black w-full sm:w-[500px]">
            <thead className='bg-green-500 text-white'>
              <tr>
                <th className="border text-center border-black p-2">{t('table.text_4')}</th>
                <th className="border text-center border-black p-2">{t('table.text_5')}</th>
                <th className="border text-center border-black p-2">{t('table.text_6')}</th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((amount, index) => (
                <tr key={index}>
                  <td className="border text-center border-black p-2"><span className='font-bold'>$</span> {amount}</td>
                  <td className="border text-center border-black p-2 overflow-hidden">{generateRandomName()}</td>
                  <td className="border text-center border-black p-2">
                    <img src={generateRandomFlag()} alt={`Flag ${index}`} className='mx-auto' />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
