import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Work from "../Assets/Work.jpg";
import Vision from "../Assets/Vision.jpg";
import Verification from "../Assets/Verification.jpg";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

function TradingConditions() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <Trial />
      <div className="w-full pb-10 text-white">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-gray-800 rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              {t('tra-con.head')}
            </h1>
          </div>
        </div>
      </div>

      <div className="py-16 w-full px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h1 className="text-gray-800 font-bold md:text-5xl sm:text-4xl text-3xl pb-8">
              {t('tra-con.head-2')}
            </h1>
            <h2 className="text-gray-800 font-bold text-[15px]">
              {t('tra-con.head-3')}
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
            {t('tra-con.text-1')}
            </p>

            <h2 className="text-gray-800 font-bold text-[15px]">
              {t('tra-con.head-4')}
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              
{t('tra-con.text-2')}
            </p>
          </div>
          <img
            className="w-[400px] mx-auto my-4 rounded-[20px]"
            src={Work}
            alt="img"
          />
        </div>

        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h2 className="text-gray-800 font-bold text-[15px]">
              {t('tra-con.head-5')}
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
            {t('tra-con.text-3')}
            </p>

            <h2 className="text-gray-800 font-bold text-[15px]">
              {t('tra-con.head-6')}
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
            {t('tra-con.text-4')}
            </p>
          </div>
          <img
            className="w-[600px] mx-auto my-4 rounded-[20px]"
            src={Vision}
            alt="img"
          />
        </div>
      </div>

      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-gray-800 rounded-[50px]">
          <div className="flex flex-col justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              {t('tra-con.head-7')}
            </h1>
            <p className="mt-8 mx-5 text-center">
            {t('tra-con.text-5')}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full text-[rgb(111,111,111)] py-[6rem] px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-10">
          <img
            className="w-[500px] mx-auto my-4 rounded-[20px]"
            src={Verification}
            alt="img"
          />
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl font-bold">{t('tra-con.head-8')}</h1>
            <p className="p-3">
              {t('tra-con.text-6')}
            </p>
            <h1 className="text-2xl font-bold">{t('tra-con.head-9')}</h1>
            <p className="p-3">
            {t('tra-con.text-7')}
<ol>
<li>{t('tra-con.text-8')}</li>
<li>{t('tra-con.text-9')}</li>
</ol>
            </p>

            <ul className="list-disc mx-9">
              <li>
                <p>{t('tra-con.text-10')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-11')}</p>
              </li>
            </ul>

            <h1 className="text-2xl font-bold">
              {t('tra-con.head-10')}
            </h1>
            <ul className="list-disc mx-9 p-3">
              <li>
                <p>{t('tra-con.text-12')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-13')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-14')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-15')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-16')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-17')}</p>
              </li>
              <li>
                <p>{t('tra-con.text-18')}</p>
              </li>
            </ul>

            <h1 className="text-2xl fot-bold p-3 font-bold">{t('tra-con.head-11')}</h1>
            <ul className="list-disc mx-9">
              <li>
                <p>
                {t('tra-con.text-19')}
                </p>
              </li>

              <li>
                <p className="my-2">
                  
{t('tra-con.text-20')}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-200 px-4">
        <div className="max-w-[1240px] mx-auto">
          <div className="flex flex-col justify center">
            <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl">
              {t('tra-con.head-12')}
            </h1>
            <ol className="list-decimal p-3">
              <li className="my-4">
                <p>{t('tra-con.text-21')}</p>
              </li>
              <li className="my-4">
                <p>
                {t('tra-con.text-22')}
                </p>
              </li>
              <li className="my-4">
                <p>{t('tra-con.text-23')}</p>
              </li>
              <li className="my-4">
                <p>
                {t('tra-con.text-24')}
                </p>
              </li>
              <li className="my-4">
                <p>
                {t('tra-con.text-25')}
                </p>
              </li>
              <li className="my-4">
                <p>
                {t('tra-con.text-26')}
                </p>
              </li>
              <li className="my-4">
                <p>
                {t('tra-con.text-27')}
                </p>
              </li>
              <li className="my-4">
                <p>{t('tra-con.text-28')}</p>
              </li>
              <li className="my-4">
                <p>{t('tra-con.text-29')}</p>
              </li>
              <li className="my-4">
                <p>{t('tra-con.text-30')}</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TradingConditions;
