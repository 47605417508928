import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Navbar from "../Components/Navbar";
import Trial from "../Components/Trial";
import CEO from "../Assets/CEEO.jpg"
import { IoMdCheckmarkCircle } from "react-icons/io";
import Team2 from "../Assets/Team-6.jpg"
import Team3 from "../Assets/Team-4.jpg"
import Team4 from "../Assets/Team-2.jpg"
import Team5 from "../Assets/Team-5.jpg"
import Team6 from "../Assets/Team-3.webp"
import Team7 from "../Assets/Team-1.jpg"
import vision from "../Assets/Investment-Trends.jpg"
import mission from "../Assets/MissionJP.jpg"
import Footer from "../Components/Footer";

function Foundation() {

    const { t, i18n } = useTranslation();
    return (
        <div>
            <Navbar />
            <Trial />
            <div className="w-full pb-10">
                <div className="px-4 pb-10 mt-[36px] max-w-[1240px] mx-auto bg-gray-800 rounded-[50px]">
                    <div className="flex justify-center">
                        <h1 className="mt-8 text-white mx-auto text-center text-4xl md:text-7xl font-bold">
                            {t('founders.head')}
                        </h1>
                    </div>
                </div>
                <div className="max-w-[1240px] grid md:grid-cols-2 gap-8 mx-auto mt-5 md:mt-20">
                    <h1 className="text-gray-800 font-bold md:text-6xl text-center text-4xl">{t('founders.head-text-1')}</h1>
                    <div className="flex flex-col">
                        <p className="p-3 text-[rgb(111,111,111)] font-bold">
                            {t('founders.head-text-2')}
                        </p>

                        <p className="p-3 text-[rgb(111,111,111)] font-bold">
                            {t('founders.head-text-3')}
                        </p>

                        <p className="p-3 text-[rgb(111,111,111)] font-bold">
                            {t('founders.head-text-4')}
                        </p>
                    </div>
                </div>

                <h1 className="text-4xl md:text-6xl text-gray-800 mt-5 md:mt-20 mx-auto text-center">{t('founders.team')}</h1>
                <div className="relative p-6">
                    <div className="absolute bottom-0 left-0 w-full">
                        <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                        <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
                        <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                    </div>
                </div>

                <div className="max-w-[1240px] grid md:grid-cols-2 gap-8 mx-auto mt-5 md:mt-20">
                    <img src={CEO} alt="/img" className="W-[300px] rounded-lg md:ml-5 p-3" />

                    <div className="mx-10 flex flex-col ">
                        <h1 className="text-gray-800 text-2xl md:text-3xl">Raymond Parlore</h1>
                        <h2 className="font-bold mt-3">CEO</h2>
                        <p className="p-3 text-[rgb(111,111,111)] font-bold">
                            {t('founders.team-lead-text')}
                        </p>

                        <div className="relative mt-10">
                            <div className="absolute bottom-0 left-0 w-full">
                                <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                            </div>
                        </div>

                        <div className="flex flex-row mt-5">
                            <IoMdCheckmarkCircle className="inliine text-gray-800 " size={40}/>
                        <p className="p-3 mt-[-10px] text-[rgb(111,111,111)] font-bold">
                            {t('founders.team-lead-xp-1')}
                        </p></div>
                        <div className="flex flex-row mt-5">
                            <IoMdCheckmarkCircle className="inliine text-gray-800 " size={40}/>
                        <p className="p-3 mt-[-10px] text-[rgb(111,111,111)] font-bold">
                            {t('founders.team-lead-xp-2')}
                        </p></div>
                        <div className="flex flex-row mt-5">
                            <IoMdCheckmarkCircle className="inliine text-gray-800 " size={40}/>
                        <p className="p-3 mt-[-10px] text-[rgb(111,111,111)] font-bold">
                            {t('founders.team-lead-xp-3')}
                        </p></div>
                    </div>
                </div>


                    <h1 className=" p-4 text-center text-gray-800 text-3xl mt-5 md:mt-10 md:text-5xl">{t('founders.director-head')}</h1>
                    <div className="relative mt-10">
                            <div className="absolute bottom-0 left-0 w-full">
                                <div className="h-0.5 bg-gray-800 w-full  mx-auto mb-1"></div> {/* Short line on top */}
                            </div>
                        </div>
                        <div className="bg-gray-100 pb-10">
                <div className=" max-w-[1240px]  mx-auto grid grid-cols-2 md:grid-cols-3 p-3 mt-5 md:mt-20 gap-10">
                    
                    <div className="mt-10 bg-white rounded-lg flex flex-col">
                        <img src={Team7} alt="/img" className="w-full mx-auto " />
                        
                        <h1 className="text-center text-gray-800 mt-5 text-xl md:text-xl font-medium">{t('founders.director-name-1')}</h1>
                        <h2 className="mt-5 text-center text-gray-800 pb-10 font-bold tracking-wider">COO</h2>
                        
                    </div>

                    <div className="mt-10 bg-white rounded-lg flex flex-col">
                        <img src={Team2} alt="/img" className="w-full mx-auto " />
                        
                        <h1 className="text-center text-gray-800 mt-5 text-xl md:text-xl font-medium">{t('founders.director-name-2')}</h1>
                        <h2 className="mt-5 text-center text-gray-800 pb-10 font-bold tracking-wider">CCO</h2>
                        
                    </div>

                    <div className="mt-10 bg-white rounded-lg flex flex-col">
                        <img src={Team3} alt="/img" className="w-full mx-auto " />
                        
                        <h1 className="text-center text-gray-800 mt-5 text-xl md:text-xl font-medium">{t('founders.director-name-3')}</h1>
                        <h2 className="mt-5 text-center text-gray-800 pb-10 font-bold tracking-wider">CFO</h2>
                        
                    </div>

                    <div className="mt-10 bg-white rounded-lg flex flex-col">
                        <img src={Team4} alt="/img" className="w-full mx-auto " />
                        
                        <h1 className="text-center text-gray-800 mt-5 text-xl md:text-xl font-medium">{t('founders.director-name-4')}</h1>
                        <h2 className="mt-5 text-center text-gray-800 pb-10 font-bold tracking-wider">CTO</h2>
                        
                    </div>

                    <div className="mt-10 bg-white rounded-lg flex flex-col">
                        <img src={Team5} alt="/img" className="w-full mx-auto " />
                        
                        <h1 className="text-center text-gray-800 mt-5 text-xl md:text-xl font-medium">{t('founders.director-name-5')}</h1>
                        <h2 className="mt-5 text-center text-gray-800 pb-10 font-bold tracking-wider">Portfolio Manager</h2>
                        
                    </div>

                    <div className="mt-10 bg-white rounded-lg flex flex-col">
                        <img src={Team6} alt="/img" className="w-full mx-auto " />
                        
                        <h1 className="text-center text-gray-800 mt-5 text-xl md:text-xl font-medium">{t('founders.director-name-6')}</h1>
                        <h2 className="mt-5 text-center text-gray-800 pb-10 font-bold tracking-wider">Marketing Manager</h2>
                        
                    </div>
                    
                </div>
                </div>

                            <h1 className="text-4xl md:text-6xl text-gray-800 text-center mt-5 md:mt-20 font-bold">{t('founders.vision-head')}</h1>
                            <div className="relative p-6">
                    <div className="absolute bottom-0 left-0 w-full">
                        <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                        <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
                        <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                    </div>
                </div>
                <div className="p-3 max-w-[1240px] mx-auto gap-20 grid md:grid-cols-2 mt-5 md:mt-20">
                        <img src={vision} alt="/img" className="rounded-md " />

                        <div className="flex flex-col">
                        <p className="p-3 text-[rgb(111,111,111)] font-bold">{t('founders.vision-text')}
                        </p>
                        <p className="p-3 mt-[10px] text-[rgb(111,111,111)] font-bold">{t('founders.vision-text-2')}
                        </p>
                        <p className="p-3 mt-[10px] text-[rgb(111,111,111)] font-bold">{t('founders.vision-text-3')}
                        </p>
                        </div>
                </div>


                <h1 className="text-4xl md:text-6xl text-gray-800 text-center mt-5 md:mt-20 font-bold">{t('founders.mission-head')}</h1>
                            <div className="relative p-6">
                    <div className="absolute bottom-0 left-0 w-full">
                        <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                        <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
                        <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                    </div>
                </div>
                <div className="p-3 max-w-[1240px] mx-auto gap-20 grid md:grid-cols-2 mt-5 md:mt-20">
                        <img src={mission} alt="/img" className="rounded-md " />

                        <div className="flex flex-col">
                        <p className="p-3 text-[rgb(111,111,111)] font-bold">{t('founders.mission-text')}
                        </p>
                        <p className="p-3 mt-[10px] text-[rgb(111,111,111)] font-bold">{t('founders.mission-text-2')}
                        </p>
                        
                        </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Foundation
