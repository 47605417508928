import React from "react";
import Navbar from "../Components/Navbar";
import Team from "../Assets/team.jpg";
import { Link } from "react-router-dom";
import licenseData from "./LicenceInput";
import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

function License() {

  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <Trial />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto h-[20vh] md:h-[20vh] bg-gray-800 rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
            {t('lice-a.head')}
            </h1>
          </div>
        </div>

        <div className="py-16 w-full px-4 h-[100vh] lg:h-[80vh] md:h-[40vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl">
                {t('lice-a.head-2')}
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
              {t('lice-a.text-1')}
              </p>
              <h4 className="underline font-bold text-gray-800 p-3">
                <Link to="/register" className="link">
                  {t('lice-a.link-1')}
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Team}
              alt="img"
            />
          </div>
        </div>

        <div className="w-full px-4">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-4 gap-8">
            
              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-3')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-3')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-4')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-4')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-5')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-5')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-6')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-6')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-7')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-7')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-8')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-8')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-9')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-9')}
                </p>
              </div>

              <div
                className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
              >
                <h1 className="font-bold text-gray-800 text-2xl">
                  {t('lice-a.head-10')}
                </h1>
                <p className="p-3 text-[rgb(111,111,111)] font-bold">
                  {t('lice-a.text-10')}
                </p>
              </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default License;
