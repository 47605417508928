import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Finance from "../Assets/Finance.jpg";
import MarketCards from "../Components/MarketCards";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

const Counter = ({ end, maxEndValue, isFirst }) => {
  const [count, setCount] = useState(0);
  

  useEffect(() => {
    // Calculate interval speed based on the difference between end and maxEndValue
    const intervalSpeed = isFirst ? Math.ceil(5000 / (maxEndValue - end)) : Math.ceil(5000 / (maxEndValue - count)); // Adjust the constant value as needed

    const interval = setInterval(() => {
      if (count < end) {
        setCount(count + 1);
      }
    }, intervalSpeed);

    return () => clearInterval(interval);
  }, [count, end, maxEndValue, isFirst]);

  return <span>{count}</span>;
};


function Market() {

  const maxEndValue = Math.max(104, 62, 78, 200);
  const { t, i18n } = useTranslation();
  return (
    
    <div>
      <Navbar />
      <Trial />
      <div className="w-full pb-10 text-white">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-gray-800 rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              {t('mktpage.head-1')}
            </h1>
          </div>
        </div>
      </div>

      <div className="py-16 w-full px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h1 className="text-gray-800 font-bold md:text-5xl sm:text-4xl text-3xl pb-8">
              {t('mktpage.head-2')}
            </h1>
            <p className="text-[rgb(111,111,111)] p-3 font-bold">
            {t('mktpage.text-1')}
            </p>
            <h4 className="text-gray-800 underline font-bold p-3">
              <Link to="/register">{t('mktpage.link-1')}</Link>
            </h4>
          </div>
          <img
            className="w-[600px] mx-auto my-4 rounded-[20px]"
            src={Finance}
            alt="img"
          />
        </div>
      </div>
      <MarketCards />

      <div className="w-full bg-gray-200 px-4 py-[6rem]">
        <div className="max-w-[1240px] mx-auto">
          <h1 className="text-center text-gray-800 font-bold md:text-6xl sm:text-4xl text-4xl py-7">
            {t('mktpage.head-3')}
          </h1>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 text-center">
           
          <div>
        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
          <Counter end={104} maxEndValue={maxEndValue} isFirst/>K+
        </h1>
        <p className="text-gray-800 text-2xl">{t('mktpage.text-2')}</p>
      </div>
      <div>
        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
          <Counter end={62} maxEndValue={maxEndValue} />+
        </h1>
        <p className="text-gray-800 text-2xl">{t('mktpage.text-3')}</p>
      </div>
      <div>
        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
          <Counter end={78} maxEndValue={maxEndValue} />+
        </h1>
        <p className="text-gray-800 text-2xl">{t('mktpage.text-4')}</p>
      </div>
      <div>
        <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
          <Counter end={120} maxEndValue={maxEndValue} />+
        </h1>
        <p className="text-gray-800 text-2xl">{t('mktpage.text-5')}</p>
      </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Market;
