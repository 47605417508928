import React from "react";
import communityData from "./CommunityInput";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import '../i18n'

function Community() {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-full py[6rem] px-4">
      <Fade top duration={3000} triggerOnce>
      <h1 className="font-bold text-gray-800 text-3xl sm:text-4xl md:text-5xl my-8 text-center">
      {t('community.head')}
      </h1>
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
        {communityData.map((communityData, id) => (
          <div
            key={id}
            className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-gray-800 transform transition-transform duration-700 hover:scale-90"
          >
            <img className="rounded-lg" src={communityData.image} alt="img" />
            <h1 className="font-bold text-white">{communityData.name}</h1>
          </div>
        ))}
      </div>
      </Fade>
    </div>
  );
}

export default Community;
