import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Input,
} from "@chakra-ui/react";
import { FcCurrencyExchange } from "react-icons/fc";
import { useAuth } from "../Api/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddAmountModal({ isOpen, onClose,user }) {
  const { AddMoney } = useAuth();
  const [currency, setcurrency] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (e) => {
    setIsLoading(true);
    if (amount && Number(amount)) {
      try {
        const res = await AddMoney(Number(amount));
        if (res.data.status) {
          toast.success(res?.data?.message);
          setIsLoading(false);
          window.location.href = "/dashboard";
        } else {
          toast.error(res?.data?.message);
          setIsLoading(false);
        }
      } catch (err) {
        toast.error("an error occured");
        setIsLoading(false);
      }
    } else {
      toast.error("Enter Amount");
      setIsLoading(false);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deposit</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            icon={<FcCurrencyExchange />}
            placeholder="Select Currency"
            variant="filled"
            onChange={(e) => {
              setcurrency(e.target.value);
            }}
          >
          
            <option value="Bitcoin">Bitcoin</option>
            <option value="Ethereum">Ethereum</option>
          </Select>
          {currency && currency === "Dollars" && (
            <Input
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              className="mt-4"
              variant="filled"
              placeholder="Enter Amount"
            />
          )}
          {currency && currency === "Bitcoin" && (
            <p className="bg-blue-300 p-4 mt-4 rounded-sm ">
              bc1qzm5k0aqd4nc60w3n6qm5lteqhr97uc424yakqj
            </p>
          )}
          {currency && currency === "Ethereum" && (
            <p className="bg-blue-300 p-4 mt-4 rounded-sm ">
              0xedd640874aa5e043f153d31e4b38557d1542c202
            </p>
          )}

          <ToastContainer />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={onClose}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          {currency && currency === "Dollars" && (
            <Button colorScheme="blue" isDisabled={!amount} onClick={onSubmit}>
              {isLoading ? "loading" : "Add"}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddAmountModal;
