import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaKey, FaArrowLeft } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../Api/useAuth";
import { setCookie, parseCookies } from "nookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Components/Navbar";
import { useTranslation } from 'react-i18next';
import '../i18n'

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

function Login() {
  const { t, i18n } = useTranslation();
  
  const cookies = parseCookies();
  const { userId } = cookies;
  useEffect(() => {
    if (userId) {
      window.location.href = "/dashboard";
    }
  }, [window, cookies]);

  const [isLoading, setIsLoading] = useState(false);

  //get the signIn fro useAuth
  const { signIn } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("clicked");
      setIsLoading(true);
      const data = {
        email: values.email,
        password: values.password,
      };
      try {
        const res = await signIn(data);
        console.log(res, "grom res");
        if (res.data.status) {
          toast.success(res?.data?.message);
          setCookie(null, "userId", res?.data?.data, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          });
          window.location.href = "/dashboard";
        } else {
          toast.error(res?.data?.message);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error(err?.message);
        console.log(err);
        setIsLoading(false);
      }
    },
  });
  if (userId) {
    window.location.href = "/dashboard";
    return null;
  }
  return (
    <div className="antialiased">
    <Navbar/>
    <div className="flex relative">
           <Link to="/">
             <FaArrowLeft className="absolute mx-2 mt-1" />
             <h1 className="underline 2xl mx-8 font-bold">{t('log-page.go-back')}</h1>
           </Link>
         </div>
         <h1 className="text-4xl mx-auto flex justify-center mb-10 font-bold">
           <Link to="/">{t('log-page.head')}</Link>
         </h1>
    <div className="flex w-full min-h-screen justify-center items-center pb-[4rem]">
  <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-gray-800 w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
    <div className="relative flex justify-center items-center w-full">
      <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28"></div>
      <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16"></div>
      <div className="relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
      <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col justify-center"
          >
            <h1 className="mx-auto font-bold pt-4 pb-6">{t('log-page.email')}</h1>
            <FaEnvelope className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.email}</p>
            <input
              className="mx-auto p-2 border border-gray-400 rounded-md w-[200px] "
              placeholder="name@gmail.com"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />

            <h1 className="mx-auto font-bold pt-4 pb-6">{t('log-page.pass')}</h1>
            <FaKey className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.password}</p>
            <input
              className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
              placeholder={t('log-page.in-pass')}
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              type="password"
            />

            <button
              type="submit"
              className="bg-gray-800 mx-auto w-[150px] md:w-[250px] mt-10 mb-6 text-2xl p-2 rounded-[20px] text-white hover:bg-blue-400 hover:text-gray-800"
            >
              {t('log-page.l-button')}
            </button>
            <p className="text-black mx-auto pb-6">
            {t('log-page.log')}{" "}
              <span className="font-bold">
                <Link to="/register">{t('log-page.log-link')}</Link>
              </span>
            </p>
            <p className="text-black mx-auto">
            {t('log-page.c-right')}
            </p>
            <ToastContainer />
          </form>
      </div>
    </div>
  </div>
</div>


    </div>
  );
}

export default Login;
