import React, { useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaKey,
  FaPhone,
  FaUserCheck,
  FaArrowLeft,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../Api/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Components/Navbar";
import { useTranslation } from 'react-i18next';
import '../i18n'

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  referalId: Yup.string(),
});

function Register() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  //get the signup fro useAuth
  const { signUp } = useAuth();
  const formik = useFormik({
    initialValues: {
      username: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      referalId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("clicked");
      setIsLoading(true);
      const data = {
        fullName: values.fullName,
        email: values.email,
        password: values.password,
        userName: values.username,
        phoneNumber: values.phoneNumber,
      };
      try {
        const res = await signUp(data, values.referalId);
        console.log(res, "grom res");
        if (res.data.status) {
          toast.success(res?.data?.message);
          window.location.href = "/login";
        } else {
          toast.error(res?.data?.message);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error(err?.message);
        console.log(err);
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="antialiased">
      <Navbar/>
      
        <div className="flex relative">
          <Link to="/">
            <FaArrowLeft className=" absolute mx-2 mt-1" />
            <h1 className="underline 2xl  mx-8 font-bold">{t('reg-page.go-back')}</h1>
          </Link>
        </div>
        <h1 className="text-4xl  mx-auto flex justify-center mb-10 font-bold">
          <Link to="/">{t('reg-page.head')}</Link>
        </h1>
        <div className="flex w-full min-h-screen justify-center items-center pb-[4rem]">
        <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-gray-800 w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
          <div className="relative flex justify-center items-center w-full">
          <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28"></div>
      <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16"></div>
        <div className="relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
          <h1 className="text-gray-800 font-bold text-2xl mx-auto flex justify-center pb-8">
          {t('reg-page.c-acc')}
          </h1>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col justify-center"
          >
            <h1
              className="mx-auto font-bold pt-4 gap-4 pb-6"
              htmlFor="username"
            >
              {t('reg-page.uname')}
            </h1>
            <FaUser className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.username}</p>
            <input
              id="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              // icon={<LockClosedIcon />}
              className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
              placeholder={t('reg-page.e-uname')}
              type="text"
              required
            />
            <h1 className="mx-auto font-bold pt-4 pb-6">{t('reg-page.fname')}</h1>
            <FaUserCheck className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.fullName}</p>
            <input
              className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
              id="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              placeholder={t('reg-page.e-fname')}
            />

            <h1 className="mx-auto font-bold pt-4 pb-6">{t('reg-page.email')}</h1>
            <FaEnvelope className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.email}</p>
            <input
              className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder={t('reg-page.e-email')}
            />

            <h1 className="mx-auto font-bold pt-4 pb-6">{t('reg-page.phone')}</h1>
            <FaPhone className="mx-auto mb-3" />
            <p className="text-red-500 text-center">
              {formik.errors.phoneNumber}
            </p>
            <input
              className="mx-auto text-black p-2  border border-gray-400 rounded-md w-[200px]"
              id="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              placeholder={t('reg-page.e-phone')}
            />

            <h1 htmlFor="password" className="mx-auto font-bold pt-4 pb-6">
            {t('reg-page.pass')}
            </h1>

            <FaKey className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.password}</p>
            <input
              className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
              placeholder={t('reg-page.ch-pass')}
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              type="password"
              required
            />
            <h1 htmlFor="confirm_pwd" className="mx-auto font-bold pt-4 pb-6">
            {t('reg-page.c-pass')}
            </h1>

            <FaKey className="mx-auto mb-3" />
            <p className="text-red-500 text-center">
              {formik.errors.confirmPassword}
            </p>
            <input
              className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
              placeholder={t('reg-page.c-pass-2')}
              id="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              type="password"
              required
            />
            <h1 className="mx-auto font-bold pt-4 pb-6">{t('reg-page.ref')}</h1>
            <FaUser className="mx-auto mb-3" />
            <input
              className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
              placeholder={t('reg-page.opt')}
            />

            <button
              type="submit"
              className="bg-gray-800 mx-auto w-[150px] md:w-[250px] mt-10 mb-6 text-2xl p-2 rounded-[20px] text-white hover:bg-blue-400 hover:text-gray-800"
            >
              {/* {isLoading ? "loading" : "Create User"} */}
              {t('reg-page.create')}
            </button>
          </form>
          <p className="text-black mx-auto pb-6">
          {t('reg-page.log')}{" "}
            <span className="font-bold">
              <Link to="/login">{t('reg-page.log-link')}</Link>
            </span>
          </p>
          <p className="text-black mx-auto">
          {t('reg-page.c-right')}
          </p>
          <ToastContainer />
        </div>
        </div>
        </div>
        </div>
      </div>
  );
}

export default Register;
