import React from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n'; // Initialize i18next

function Trial() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="p-4">
      <div className='flex'>
      <h1 className='font-medium text-2xl mx-4'>Select Language</h1>
      <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
        <option value="en">English</option>
        <option value="fr">French</option>
        <option value="de">German</option>
        <option value="rs">Russian</option>
      </select>
      </div>
    </div>
  );
}

export default Trial;
