import React from "react";
import Navbar from "../Components/Navbar";
import Features from "../Components/Features";
import Footer from "../Components/Footer";
import Office from "../Assets/Office.jpg";
import Indicies from "../Assets/Indices.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

function Indices() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Navbar />
      <Trial />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-gray-800 rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-4xl md:text-7xl font-bold">
            {t('ind-page.head-1')}
            </h1>
          </div>
        </div>

        <div className="py-16 w-full px-4 h-[90vh] md:h-[30vh] lg:h-[80vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h1 className="text-gray-800 font-bold md:text-6xl sm:text-4xl text-3xl">
                {t('ind-page.head-2')}
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
              {t('ind-page.text-1')}
              </p>
              <h4 className="underline font-bold text-gray-800 p-3">
                <Link to="/register" className="link">
                  {t('ind-page.link')}
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Indicies}
              alt="img"
            />
          </div>
        </div>

        <div className="w-full px-4 py-[4rem] md:h-[30vh] lg:h-[60vh] h-[110vh]">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('ind-page.head-3')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
              {t('ind-page.text-2')}
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('ind-page.head-4')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
              {t('ind-page.text-3')}
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-gray-800 text-2xl">
                {t('ind-page.head-5')}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
              {t('ind-page.text-4')}
              </p>
            </div>
          </div>
        </div>
        <Features />

        <div className="py-20 px-4 h-[110vh] md:h-[40vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <img
              className="w-[500px] mx-auto my-4 rounded-[20px]"
              src={Office}
              alt="img"
            />
            <div className="flex flex-col justify-center">
              <h1 className="text-gray-800 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
                {t('ind-page.head-6')}
              </h1>

              <p className="p-3 text-[rgb(111,111,111)] font-bold">
              {t('ind-page.text-5')}
              </p>

              <h4 className="underline font-bold text-gray-800 p-3">
                <Link to="/register" className="link">
                  {t('ind-page.link')}
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Indices;
